.content {
  flex: 1;
  left: 50%;
  margin-top: 96px;
  padding-bottom: 48px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  transform: translateX(-50%);
  h1 {
    font-size: 40px;
    margin: 0;
    padding-top: 12px;
  }
  h2 {
    font-size: 28px;
    text-align: left;
  }
  h3 {
    font-size: 24px;
    margin-bottom: 12px;
    text-align: left;
  }
  h4 {
    font-size: 20px;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  p, li {
    font-size: 20px;
    line-height: 1.4;
    text-align: justify;
  }
  ul {
    li {
      margin-bottom: 12px;
      div.list-item-container {
        display: flex;
        flex-direction: row;
        div.list-item-container-text {
          background-color: white;
          &.quote {
            p {
              color: rgba(0,0,0, 0.5);
              font-style: italic;
            }
          }
        }
        div.list-item-container-image {
          background-color: white;
          margin-left: 12px;
          img {
            width: 96px;
          }
        }
      }
    }
  }
  .image-container {
    text-align: center;
  }
}

@import 'media.scss';
