footer {
  background-color: #181a32;
  background-image: url("../../assets/header-bg.jpg");
  background-position: 50% 0;
  background-repeat: no-repeat;
  bottom: 0;
  height: 12px;
  position: fixed;
  width: 100vW;
}
