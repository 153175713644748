.content {
  &.screen-time {
    .pie-chart-container {
      width: 100%;
      height: 600px;
      &.google {
        border-top: 0px solid black;
        height: 300px;
      }
    }
  }
}
