header {
  background-color: #181a32;
  background-image: url("../../assets/header-bg.jpg");
  background-position: 100% 0;
  background-repeat: no-repeat;
  height: 96px;
  position: fixed;
  top: 0;
  width: 100vW;
  z-index: 100;
}
