@media only screen and (max-width: 700px) {
  .content {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
} 
// /* Small screen devices (600px and above) */
// @media only screen and (min-width: 600px) {
//   .content {}
/* Medium screen devices (768px and above) */
// @media only screen and (min-width: 768px) {
//   .content {}
// }
/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) {
  .content {
    width: 865px;
  }
}
/* Extra big screen devices (1200px and above) */
// @media only screen and (min-width: 1200px) {
//   .content {}
// }
