.content {
  &.about-this-site {
    background-color: #fff;
    div.image {
      background-repeat: no-repeat;
      background-size: 100% 100%;
      
      &.image1 {
        background-image: url("../../../assets/pages/about-this-site-01.png");
        height: 1200px;
        width: 100%;
      }
      &.image2 {
        background-image: url("../../../assets/pages/about-this-site-02.png");
        height: 150px;
        width: 300px;
      }

    }
    ul {
      li {
        margin-bottom: 12px;
      }
    }
    pre {
      background-color: #00000010;
      display: inline;
      font-family: "courier";
      padding: 0 8px;
    }
  }
}
