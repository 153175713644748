/* * {
  box-sizing: border-box;
}

#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
} */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background-color: #fff;
  border-radius: 15px;
  height: 4px !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ffffff80;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  left: 12px;
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background-color: #000;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  background-color: #2352ae;
  background-image: url("../../assets/menu-bg.jpg");
  background-position: 100% 0;
  background-size: 100% 100%;
}

/* General sidebar styles */
.bm-menu {
  /* background-color: #fff; */
  border-left: 1px solid #ffffff80;
  padding: 32px 24px 0;
  font-size: 20px;
  /* width: 400px; */
  /* box-sizing: border-box; */
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #fff;
}

/* Wrapper for item list */
.bm-item-list {
  width: 250px;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background-color: rgba(0, 0, 0, 1);
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #fff;
  margin-bottom: 16px;
  text-align: left;
  text-decoration: none;
  /* transition: color 0.2s; */
  text-wrap: wrap !important;
  width: 228px;
  border: 1px solid #ffffff80;
  padding: 12px;
  &:hover {
    color: #ffffff;
    background-color: #ffffff10;
  }
}

// .bm-item:hover {
//   color: #fff;
//   background-color: #ff00ff10;
// }
.menu-selected {
  /* background-color: #ffffff40; */
  border-bottom-width: 4px;
}
